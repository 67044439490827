export default [
  {
    header: '',
  },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    header: '',
  },
  {
    title: 'Clients',
    icon: 'UsersIcon',
    route: 'clients',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    route: 'invoices',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    title: 'Recurring Invoices',
    icon: 'ClockIcon',
    route: 'recurring-invoices',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    header: '',
  },
  {
    title: 'Products',
    icon: 'TagIcon',
    route: 'products',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    title: 'Reports',
    icon: 'PieChartIcon',
    route: 'reports',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    header: 'Help',
  },
  /*
  {
    title: 'Support',
    icon: 'HelpCircleIcon',
    route: 'support',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  */
]
